<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <div class="mt-1">
                    <span class="mr-1">
                        <i class="fas fa-add"></i>
                    </span>
                    {{ translate('add_sale') }}
                </div>
            </template>
            <b-list-group class="list-group-accent">
                <b-list-group-item class="font-weight-bold pt-2 pb-0">
                    {{ translate('add_sale_select_buyer_type') }}*
                </b-list-group-item>
                <b-form @submit.prevent="registerSale">
                    <!-- [+] Customers -->
                    <b-list-group-item tag="label" class="pt-1 pb-1 cursor-pointer">
                        <div class="row m-0 align-items-center">
                            <div class="col pl-0">
                                <input type="radio" id="customer" name="customer" class="hidden" v-model="selected_type" value="customer" @click="showCustomersList" :checkedCustomer="checkedCustomer ? 'checkedCustomer' : ''">
                                <label class="cursor-pointer" for="customer">
                                    <q-icon size="1.5rem" class="mr-1" :color="selected_type === 'customer' ? 'primary' : 'secondary'" name="fas fa-user" />
                                    {{ translate('customer') }}
                                    <q-badge class="p-2 ml-1" color="blue-6" v-if="checkedCustomer && selected_customer_name">{{ selected_customer_name }}</q-badge>
                                </label>
                            </div>
                            <div class="col-xs-12 col-sm-auto text-right p-0">
                                <q-input v-if="showCustomers" @input="isTypingCustomer = true" v-model="searchCustomer" id="filternamecustomer" class="search-input" type="text" :placeholder="translate('find_client')" :dense="true">
                                    <template v-slot:prepend>
                                        <q-icon name="search" />
                                    </template>
                                    <template v-slot:append>
                                        <q-btn :size="$q.screen.lt.sm ? '0.65rem' : null" type="button" @click.stop="showAddClientPanel" flat dense color="dark" no-caps :label="translate('new_customer')" icon="add" />
                                    </template>
                                </q-input>
                            </div>
                        </div>
                        <b-card-body v-if="showCustomers" class="card_scroll m-0 p-0">
                            <div class="list_wrapper-customer">
                                <q-item tag="label" class="list-item" clickable v-ripple v-for="customer in customers" :key="'customer-' + customer.id">
                                    <q-item-section side>
                                        <q-radio size="md" dense flat color="primary" emit-value :id="customer_value(customer.id)" :val="customer_value(customer.id)" v-model.number="selected_userType" @input="selected_customer_name=customer.name" />
                                    </q-item-section>
                                    <q-item-section>
                                        <q-item-label>{{ customer.name }}</q-item-label>
                                    </q-item-section>
                                </q-item>
                            </div>
                            <infinite-loading :identifier="infCustomers" slot="append" @infinite="getCustomer" />
                        </b-card-body>
                    </b-list-group-item>
                    <!-- [-] Customers -->


                    <!-- [+] Groups -->
                    <b-list-group-item tag="label" class="pt-1 pb-1 cursor-pointer">
                        <div class="row m-0 align-items-center">
                            <div class="col pl-0">
                                <input type="radio" id="group" name="group" class="hidden" v-model="selected_type" value="group" @click="showGroupList" :checkedGroup="checkedGroup ? 'checkedGroup' : ''">
                                <label class="cursor-pointer" for="group">
                                    <q-icon size="1.5rem" class="mr-1" :color="selected_type === 'group' ? 'primary' : 'secondary'" name="fas fa-group" />
                                    {{ translate('group') }}
                                    <q-badge class="p-2 ml-1" color="blue-6" v-if="checkedGroup && selected_group_name">{{ selected_group_name }}</q-badge>
                                </label>
                            </div>
                            <div class="col-xs-12 col-sm-auto text-right p-0">
                                <q-input v-if="showGroups" @input="isTypingGroup = true" v-model="searchGroup" id="filternamegroup" class="search-input" type="text" :placeholder="translate('find_group')" :dense="true">
                                    <template v-slot:prepend>
                                        <q-icon name="search" />
                                    </template>
                                    <template v-slot:append>
                                        <q-btn :size="$q.screen.lt.sm ? '0.65rem' : null" type="button" @click.stop="showAddGroupPanel" flat dense color="dark" no-caps :label="translate('new_group')" icon="add" />
                                    </template>
                                </q-input>
                            </div>
                        </div>
                        <b-card-body v-if="showGroups" class="card_scroll m-0 p-0">
                            <div class="list_wrapper-group">
                                <q-item tag="label" class="list-item" clickable v-ripple v-for="group in groups" :key="'group-' + group.id">
                                    <q-item-section side>
                                        <q-radio size="md" dense flat color="primary" emit-value :id="group_value(group.id)" :val="group_value(group.id)" v-model.number="selected_userType" @input="selected_group_name=group.name" />
                                    </q-item-section>
                                    <q-item-section>
                                        <q-item-label>{{ group.name }}</q-item-label>
                                    </q-item-section>
                                </q-item>
                            </div>
                            <infinite-loading :identifier="infGroups" slot="append" @infinite="getGroup" />
                        </b-card-body>
                    </b-list-group-item>
                    <!-- [-] Groups -->
                    <b-list-group-item class="font-weight-bold pt-2 pb-0">
                        {{ translate('add_sale_select_offer_type') }}*
                    </b-list-group-item>
                    <b-list-group-item tag="label" class="pt-1 pb-1 cursor-pointer">
                        <div class="row m-0 align-items-center">
                            <div class="col pl-0">
                                <input type="radio" id="service" name="service" class="hidden" v-model="selected_offers" value="service" @click="showServiceList" :checkedService="checkedService ? 'checkedService' : ''">
                                <label class="cursor-pointer" for="service">
                                    <q-icon size="1.5rem" class="mr-1" :color="selected_offers === 'service' ? 'primary' : 'secondary'" name="fas fa-swimmer" />
                                    {{ translate('service') }}
                                    <q-badge class="p-2 ml-1" color="blue-6" v-if="checkedService && selected_service_name">{{ selected_service_name }}</q-badge>
                                    <q-badge class="p-2 ml-1 text-dark" color="secondary" v-if="checkedService && selected_service_name && sales.credits">{{ translate('credits') + ': ' + sales.credits }}</q-badge>
                                </label>
                            </div>
                            <div class="col-xs-12 col-sm-auto text-right p-0">
                                <q-input v-if="showServices" @input="isTypingService = true" v-model="searchService" id="filternameservice" class="search-input" type="text" :placeholder="translate('find_service')" :dense="true">
                                    <template v-slot:prepend>
                                        <q-icon name="search" />
                                    </template>
                                    <template v-slot:append>
                                        <q-btn :size="$q.screen.lt.sm ? '0.65rem' : null" type="button" @click.stop="showAddServicePanel" flat dense color="dark" no-caps :label="translate('new_service')" icon="add" />
                                    </template>
                                </q-input>
                            </div>
                        </div>
                        <b-card-body v-if="showServices" class="card_scroll m-0 p-0">
                            <div class="list_wrapper-services">
                                <q-item tag="label" class="list-item" clickable v-ripple v-for="service in services" :key="'service-' + service.id">
                                    <q-item-section side>
                                        <q-radio size="md" dense @click.native="showCreditService" flat color="primary" emit-value :id="service_value(service.id)" :val="service_value(service.id)" v-model.number="selected" :radioService="radioService ? 'radioService' : ''" @input="selected_service_name=service.name" />
                                    </q-item-section>
                                    <q-item-section>
                                        <q-item-label>{{ service.name }}</q-item-label>
                                    </q-item-section>
                                </q-item>
                            </div>
                            <infinite-loading :identifier="'infServices-' + infServices" slot="append" @infinite="getService" />
                        </b-card-body>
                        <b-form-group v-if="showServices && showCredits">
                            <label for="credits" class="mt-2">{{ translate('credits') }}</label>
                            <b-form-input type="text" id="credits" @input="showCalculatorButton" v-model.number="sales.credits" :placeholder="translate('enter_credits')">
                            </b-form-input>
                        </b-form-group>
                    </b-list-group-item>
                    <b-list-group-item tag="label" class="pt-1 pb-1 cursor-pointer">
                        <div class="row m-0 align-items-center">
                            <div class="col pl-0">
                                <input type="radio" id="product" name="product" class="hidden" v-model="selected_offers" value="product" @click="showProductList" :checkedProduct="checkedProduct ? 'checkedProduct' : ''">
                                <label class="cursor-pointer" for="product">
                                    <q-icon size="1.5rem" class="mr-1" :color="selected_offers === 'product' ? 'primary' : 'secondary'" name="fas fa-box" />
                                    {{ translate('product') }}
                                    <q-badge class="p-2 ml-1" color="blue-6" v-if="checkedProduct && selected_product_name">{{ selected_product_name }}</q-badge>
                                    <q-badge class="p-2 ml-1 text-dark" color="secondary" v-if="checkedProduct && selected_product_name && sales.quantity">{{ translate('quantity') + ': ' + sales.quantity }}</q-badge>
                                </label>
                            </div>
                            <div class="col-xs-12 col-sm-auto text-right p-0">
                                <q-input v-if="showProducts" @input="isTypingProduct = true" v-model="searchProduct" id="filternameproduct" class="search-input" type="text" :placeholder="translate('find_product')" :dense="true">
                                    <template v-slot:prepend>
                                        <q-icon name="search" />
                                    </template>
                                    <template v-slot:append>
                                        <q-btn :size="$q.screen.lt.sm ? '0.65rem' : null" type="button" @click.stop="showAddProductPanel" flat dense color="dark" no-caps :label="translate('new_product')" icon="add" />
                                    </template>
                                </q-input>
                            </div>
                        </div>
                        <b-card-body v-if="showProducts" class="card_scroll m-0 p-0">
                            <div class="list_wrapper-product">
                                <q-item tag="label" class="list-item" clickable v-ripple v-for="product in products" :key="'product-' + product.id">
                                    <q-item-section side>
                                        <q-radio size="md" dense @click.native="showQuantityProduct" flat color="primary" emit-value :id="product_value(product.id)" :val="product_value(product.id)" v-model.number="selected" :radioProduct="radioProduct ? 'radioProduct' : ''" @input="selected_product_name=product.name" />
                                    </q-item-section>
                                    <q-item-section>
                                        <q-item-label>{{ product.name }}</q-item-label>
                                    </q-item-section>
                                </q-item>
                            </div>
                            <infinite-loading :identifier="infProducts" slot="append" @infinite="getProduct" />
                        </b-card-body>
                        <b-form-group v-if="showProducts && showQuantities">
                            <label for="quantity" class="mt-2">{{ translate('quantity') }}</label>
                            <b-form-input type="text" id="quantity" @input="showCalculatorButton" v-model.number="sales.quantity" :placeholder="translate('enter_quantity')">
                            </b-form-input>
                        </b-form-group>
                    </b-list-group-item>
                    <b-list-group-item tag="label" class="pt-1 pb-1 cursor-pointer">
                        <div class="row m-0 align-items-center">
                            <div class="col pl-0">
                                <input type="radio" id="subscription" name="subscription" class="hidden" v-model="selected_offers" value="subscription" @click="showSubscriptionList" :checkedSubscription="checkedSubscription ? 'checkedSubscription' : ''">
                                <label class="cursor-pointer" for="subscription">
                                    <q-icon size="1.5rem" class="mr-1" :color="selected_offers === 'subscription' ? 'primary' : 'secondary'" name="fas fa-atom" />
                                    {{ translate('subscription') }}
                                    <q-badge class="p-2 ml-1" color="blue-6" v-if="checkedSubscription && selected_subscription_name">{{ selected_subscription_name }}</q-badge>
                                    <q-badge class="p-2 ml-1 text-dark" color="amber" v-if="checkedSubscription && selected_subscription_name && sales.valid_from">{{ translate('valid_from') + ': ' + sales.valid_from }}</q-badge>
                                    <q-badge class="p-2 ml-1 text-dark" color="amber" v-if="checkedSubscription && selected_subscription_name && response_sales_compute.valid_until">{{ translate('valid_until') + ': ' + response_sales_compute.valid_until }}</q-badge>
                                </label>
                            </div>
                            <div class="col-xs-12 col-sm-auto text-right p-0">
                                <q-input v-if="showSubscriptions" @input="isTypingSubscription = true" v-model="searchSubscription" id="filternamesubscription" class="search-input" type="text" :placeholder="translate('find_subscription')" :dense="true">
                                    <template v-slot:prepend>
                                        <q-icon name="search" />
                                    </template>
                                    <template v-slot:append>
                                        <q-btn :size="$q.screen.lt.sm ? '0.65rem' : null" type="button" @click.stop="showAddSubscriptionPanel('predefined')" flat dense color="dark" no-caps :label="translate('new_subscription')" icon="add" />
                                    </template>
                                </q-input>
                            </div>
                        </div>
                        <b-card-body v-if="showSubscriptions" class="card_scroll m-0 p-0" :class="services_container_height === true ? 'card_scroll_doubled' : ''">
                            <div class="list_wrapper-subscriptions">
                                <div v-for="subscription in subscriptions" :key="subscription.type + '-subscription-' + subscription.id">
                                    <q-item tag="label" class="list-item" clickable v-ripple>
                                        <q-item-section side>
                                            <q-radio size="md" dense name="selected_subscription" @click.native="showSubscriptionServices(subscription.type)" flat color="primary" emit-value :id="subscription_value(subscription.id, subscription.type)" :val="subscription_value(subscription.id, subscription.type)" v-model.number="selected" :radioSubscription="radioSubscription ? 'radioSubscription' : ''" @input="selected_subscription_name=subscription.name" />
                                        </q-item-section>
                                        <q-item-section>
                                            <q-item-label>{{ subscription.name }}</q-item-label>
                                        </q-item-section>
                                    </q-item>
                                    <b-card-body v-if="(subscription.id === sales.custom_subscription_id && subscription.type === 'custom') && custom_subscription_services.length > 0" class="m-0 p-0 included-services-block">
                                        <strong class="small">{{ translate('subscription_included_services') }}</strong>
                                        <div class="text-right"><small>{{ translate('credits') }}: <strong>{{ selected_remaining_custom_subscription_credits }} / {{ selected_custom_subscription_credits }}</strong></small></div>
                                        <template class="subscription_services_list_wrapper">
                                            <q-item tag="label" class="list-item pr-0" clickable v-ripple v-for="(service,i) in custom_subscription_services" :key="'custom-subscription-service-' + i + '-' + service.service_id">
                                                <q-item-section>
                                                    <q-item-label>
                                                        {{ service.service_name }}
                                                        <strong v-if="service.service_type" class="text-muted">({{ translate(service.service_type.replace("s_", '')) }})</strong>
                                                    </q-item-label>
                                                </q-item-section>
                                                <q-item-section class="pt-2" side>
                                                    <q-input :max="max_number !== null && service.service_type === 's_streaming' ? max_number : null" no-error-icon
                                                    :rules="max_number !== null && service.service_type === 's_streaming' ? [value => value <= max_number || $toasted.error(max_number_error, {duration: toasted_duration})] : [value => value >= 0 || $toasted.error('positive_numeric_value_only', {duration: toasted_duration})]" emit-value v-model.number="service.new_number" @input="setSelectedSubscriptionServices(service, subscription.id)" @wheel="$event.target.blur()" type="number" outlined dense style="max-width: 150px">
                                                    </q-input>
                                                </q-item-section>
                                            </q-item>
                                        </template>
                                        <infinite-loading :identifier="'inf-customsubscriptionservices_'  + infCustomSubscriptionServices" slot="append" @infinite="getCustomSubscriptionServices" />
                                    </b-card-body>
                                </div>
                            </div>
                            <infinite-loading :identifier="infSubscriptions" slot="append" @infinite="getSubscription" />
                        </b-card-body>
                        <b-form-group v-if="showSubscriptions && showDate">
                            <label for="valid_from" class="mt-2">{{ translate('valid_from') }}</label>
                            <b-form-input type="date" id="valid_from" :value="getCurrentDate()" v-model="sales.valid_from" :placeholder="translate('enter_quantity')">
                            </b-form-input>
                        </b-form-group>
                    </b-list-group-item>
                    <b-list-group-item class="font-weight-bold pt-2 pb-0">
                        <div class="row m-0">
                            <div class="col p-0">
                                {{ translate('add_sale_promo_code') }}
                            </div>
                            <div class="col p-0 text-right">
                                <q-btn :size="$q.screen.lt.sm ? '0.65rem' : null" type="button" @click.stop="showAddPromoPanel" flat dense color="dark" no-caps :label="translate('new_promo')" icon="add" />
                            </div>
                        </div>
                    </b-list-group-item>
                    <b-list-group-item class="list-group-item-accent-primary">
                        <b-form-input type="text" id="promo_code" v-model="sales.promo_code" :placeholder="translate('add_sale_enter_promo_code')" />
                    </b-list-group-item>
                    <b-list-group-item v-if="showCalculator">
                        <b-row class="m-0">
                            <b-col class="pl-0">
                                <q-btn type="submit" no-caps color="info">
                                    <i class="fas fa-calculator mr-1"></i> {{ translate('calculate') }}
                                </q-btn>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                </b-form>
                <b-list-group-item v-if="showFinish">
                    <q-item class="p-0 full-width">
                        <q-item-section>
                            <strong v-if="response_sales_compute.amount != null">{{ translate('total_amount') }}: {{response_sales_compute.amount}}<template v-if="currency"> {{ currency }}</template></strong>
                        </q-item-section>
                        <q-item-section v-if="!$q.screen.lt.sm" side>
                            <q-btn @click.prevent="createSale" no-caps color="primary">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('finish_sale') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                    <q-item v-if="$q.screen.lt.sm" class="full-width p-0">
                        <q-item-section>
                            <q-btn class="full-width" @click.prevent="createSale" no-caps color="primary">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('finish_sale') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side></q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import {
    eventBus
} from '../../../main'
import AddClient from '../../user-management/customers/forms/AddCustomer'
import AddGroup from '../../user-management/groups/forms/AddGroup'
import AddService from '../../offers/services/forms/AddService'
import AddProduct from '../../offers/products/forms/AddProduct'
import AddSubscription from '../../offers/subscriptions/forms/AddSubscription'
import AddPromotion from '../../offers/promotions/forms/AddPromotion'

export default {
    name: 'AddSale',
    components: {
        // 'sale-buyer': AddSaleBuyer,
    },
    computed: {
        get_customers_url: function () {
            return baseUrl + 'customers'
        },
        get_groups_url: function () {
            return baseUrl + 'groups'
        },
        max_number_error: function () {
            return this.translate('max_amount_allowed')  + ' ' + this.max_number
        },
    },
    data: function () {
        return {
            currency: currency,
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            max_number: '1',
            toasted_duration: this.$toasted_duration,

            services_container_height: false,

            infCustomers: +new Date() + 1,
            infGroups: 'inf-groups' + new Date() + 2,
            infServices: +new Date() + 3,
            infProducts: 'inf-products',
            infSubscriptions: 'inf-subscriptions',
            infCustomSubscriptionServices: +new Date(),
            customers: [],
            groups: [],
            subscriptions: [],
            custom_subscription_services: [],
            products: [],
            services: [],
            selected: '',
            response_sales_compute: {},
            sale_compute: {},
            sales: {
                group_id: 0,
                customer_id: 0,
                service_id: 0,
                credits: 0,
                product_id: 0,
                quantity: 0,
                subscription_id: 0,
                custom_subscription_id: 0,
                selected_services: {
                    new_number: 0
                },
                valid_from: this.getCurrentDate(),
                amount: '',
                promo_code: ''
            },
            showDate: false,
            showQuantities: false,
            showCredits: false,
            showServices: false,
            showProducts: false,
            showSubscriptions: false,
            showCustomers: false,
            showGroups: false,
            showAmount: false,
            showCalculator: false,
            showFinish: false,
            checkedService: false,
            checkedProduct: false,
            checkedSubscription: false,
            checkedCustomer: false,
            checkedGroup: false,
            radioSubscription: false,
            radioService: false,
            radioProduct: false,
            isTypingService: false,
            isTypingProduct: false,
            isTypingSubscription: false,
            isTypingCustomer: false,
            isTypingGroup: false,
            page_customer: 1,
            page_group: 1,
            page_service: 1,
            page_product: 1,
            page_subscription: 1,
            page_custom_subscription_services: 1,
            total_subscription_pages: 1,
            searchService: '',
            searchProduct: '',
            searchSubscription: '',
            searchCustomer: '',
            searchGroup: '',
            message: '',
            selected_type: '',
            selected_userType: '',
            selected_offers: '',
            selected_offers_type: '',
            selected_custom_subscription_credits: 0,
            selected_remaining_custom_subscription_credits: 0,
            selected_customer_name: '',
            selected_group_name: '',
            selected_service_name: '',
            selected_product_name: '',
            selected_subscription_name: '',
            customer_active: 'active',
            group_active: 'active',
            service_active: 'active',
            product_active: 'active',
            subscription_active: 'active',

            state: {},
            go_to_bottom: false,
        }
    },
    mounted: function () {
        eventBus.$on('update_buyer_ids', (buyer) => {
            this.updateBuyerIds(buyer)
        })
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    updated: function () {
        if (this.go_to_bottom) {
            // Navigate to the bottom of the panel.
            var container = document.querySelector(".slideout-panel .slideout-wrapper .slideout")
            container.scrollTop = container.scrollHeight

            // Hide all lists.
            this.hideAllLists()

            // Reset navigation to bottom.
            this.go_to_bottom = false
        }
    },
    watch: {
        searchService: _.debounce(function () {
            this.isTypingService = false
        }, 200),
        searchProduct: _.debounce(function () {
            this.isTypingProduct = false
        }, 200),
        searchSubscription: _.debounce(function () {
            this.isTypingSubscription = false
        }, 200),
        searchCustomer: _.debounce(function () {
            this.isTypingCustomer = false
        }, 200),
        searchGroup: _.debounce(function () {
            this.isTypingGroup = false
        }, 200),
        isTypingService: function (value) {
            if (!value) {
                this.page_service = 1
                this.services = []
                this.getService(this.state)
            }
        },
        isTypingProduct: function (value) {
            if (!value) {
                this.page_product = 1
                this.products = []
                this.getProduct(this.state)
            }
        },
        isTypingSubscription: function (value) {
            if (!value) {
                this.page_subscription = 1
                this.subscriptions = []
                this.getSubscription(this.state)
            }
        },
        isTypingCustomer: function (value) {
            if (!value) {
                this.page_customer = 1
                this.customers = []
                this.getCustomer(this.state)
            }
        },
        isTypingGroup: function (value) {
            if (!value) {
                this.page_group = 1
                this.groups = []
                this.getGroup(this.state)
            }
        }
    },
    created: function () {
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        showAddClientPanel: function () {
            const panelInstance = this.$showPanel({
                component: AddClient
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetClientsList()
                }
            })
        },
        resetClientsList: function () {
            this.page_customer = 1
            this.customers = []
            this.infCustomers++
        },
        showAddGroupPanel: function () {
            const panelInstance = this.$showPanel({
                component: AddGroup
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetGroupsList()
                }
            })
        },
        resetGroupsList: function () {
            this.page_group = 1
            this.groups = []
            this.infGroups++
        },
        showAddServicePanel: function () {
            const panelInstance = this.$showPanel({
                component: AddService
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetServicesList()
                }
            })
        },
        resetServicesList: function () {
            this.page_service = 1
            this.services = []
            this.infServices++
        },
        showAddProductPanel: function () {
            const panelInstance = this.$showPanel({
                component: AddProduct
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetProductsList()
                }
            })
        },
        resetProductsList: function () {
            this.page_product = 1
            this.products = []
            this.infProducts++
        },
        showAddSubscriptionPanel: function (type) {
            const panelInstance = this.$showPanel({
                component: AddSubscription
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetSubscriptionsList()
                }
            })
        },
        showAddPromoPanel: function (type) {
            const panelInstance = this.$showPanel({
                component: AddPromotion,
                props: {
                    selected_promo_type: 'promo_code',
                    focues_input: true
                }
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    // Maybe fill in the new code?
                }
            })
        },
        resetSubscriptionsList: function () {
            this.page_subscription = 1
            this.subscriptions = []
            this.infSubscriptions++
        },
        // newcode.
        updateBuyerIds: function (buyer) {
            this.sales.group_id = buyer.type === 'group' ? buyer.id : 0
            this.sales.customer_id = buyer.type === 'customer' ? buyer.id : 0

        },
        // oldcode.
        resetSelectedOffers: function () {
            this.selected = ''
            this.sales.quantity = 0
            this.sales.credits = 0
        },
        resetBuyerLists: function () {
            this.checkedCustomer = false
            this.showCustomers = false
            this.checkedGroup = false
            this.showGroups = false
            this.selected_customer_name = ''
            this.selected_group_name = ''
        },
        resetOfferLists: function () {
            this.checkedService = false
            this.checkedProduct = false
            this.checkedSubscription = false
            this.showServices = false
            this.showProducts = false
            this.showSubscriptions = false
            this.selected_service_name = ''
            this.selected_product_name = ''
            this.selected_subscription_name = ''
        },
        hideAllLists: function () {
            this.showCustomers = false
            this.showGroups = false
            this.showServices = false
            this.showProducts = false
            this.showSubscriptions = false
        },
        resetSalesIds: function () {
            this.sales.subscription_id = 0
            this.sales.custom_subscription_id = 0
            this.sales.service_id = 0
            this.sales.product_id = 0
        },
        showSubscriptionServices: function (subscription_type) {
            this.services_container_height = false
            this.custom_subscription_services = []
            this.sales.selected_services = {}
            this.selected_offers_type = ''

            this.checkedSubscription = true
            this.selected_offers = 'subscription'
            this.sales.custom_subscription_id = 0
            this.sales.subscription_id = this.target_ids()

            if (subscription_type === 'custom') {
                this.selected_offers_type = 'custom_subscription'

                // Reset .sales id values.
                this.resetSalesIds()

                // Set the custom_subscription_id value.
                this.sales.custom_subscription_id = this.target_ids()

                this.getCustomSubscriptionServices(this.state)
            }

            this.showDateValid()
        },
        setRemainingCredits: function () {
            var credits = 0

            if (this.sales.selected_services) {
                for (let [key, value] of Object.entries(this.sales.selected_services)) {
                    credits = credits + this.sales.selected_services[key]
                }
            }

            this.selected_remaining_custom_subscription_credits = parseInt(this.selected_custom_subscription_credits) - parseInt(credits)
        },
        setSelectedSubscriptionServices: function (service, custom_subscription_id) {
            // Set the current selected offer type.
            this.selected_offers_type = 'custom_subscription'
            // Set the custom_subscription_id value.
            this.sales.custom_subscription_id = this.target_ids()

            // Build the object data.
            var service_credits = parseInt(service.new_number) ? parseInt(service.new_number) : 0
            var service_id = service.service_id
            var service_id_string = service_id.toString()

            this.sales.selected_services[service_id_string] = parseInt(service_credits)

            if (this.sales.selected_services[service_id_string] === 0) {
                // remove it from array.
                delete this.sales.selected_services[service_id_string]
            }

            this.setRemainingCredits()
        },
        getCustomSubscriptionInformation: function () {
            var id = this.sales.custom_subscription_id
            var subscriptionUrlCustom = baseUrl +
                'custom-subscriptions/' + id
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            axios.get(subscriptionUrlCustom, {
                    headers: headers
                })
                .then(response => {
                    this.selected_custom_subscription_credits = response.data.item.included_credits
                    this.selected_remaining_custom_subscription_credits = response.data.item.included_credits
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showDateValid: function () {
            if (!this.radioSubscription) {
                this.radioSubscription = true
                this.showDate = true
                this.showAmount = true
                this.showCalculator = true
            }
        },
        showQuantityProduct: function () {
            if (!this.radioProduct) {
                this.radioProduct = true
                this.showQuantities = true
                this.showAmount = true
            }
        },
        showCreditService: function () {
            if (!this.radioService) {
                this.radioService = true
                this.showCredits = true
                this.showAmount = true
            }
        },
        showCalculatorButton: function () {
            this.showCalculator = true
        },
        showCustomersList: function () {
            if (!this.checkedCustomer) {
                this.hideAllLists()
                this.resetBuyerLists()
                this.selected_userType = null
                this.checkedCustomer = true
            }
            this.showCustomers = !this.showCustomers
        },
        showGroupList: function () {
            if (!this.checkedGroup) {
                this.hideAllLists()
                this.resetBuyerLists()
                this.selected_userType = null
                this.checkedGroup = true
            }
            this.showGroups = !this.showGroups
        },
        showServiceList: function () {
            if (!this.checkedService) {
                this.hideAllLists()
                this.resetOfferLists()
                this.resetSelectedOffers()
                this.checkedService = true
            }

            this.showServices = !this.showServices
        },
        showProductList: function () {
            if (!this.checkedProduct) {
                this.hideAllLists()
                this.resetOfferLists()
                this.resetSelectedOffers()
                this.checkedProduct = true
            }

            this.showProducts = !this.showProducts
        },
        showSubscriptionList: function () {
            if (!this.checkedSubscription) {
                this.hideAllLists()
                this.resetOfferLists()
                this.resetSelectedOffers()
                this.checkedSubscription = true
            }

            this.showSubscriptions = !this.showSubscriptions
        },
        customer_value: function (element_id) {
            return element_id
        },
        group_value: function (element_id) {
            return element_id
        },
        service_value: function (element_id) {
            return 'service_' + element_id
        },
        subscription_value: function (element_id, subscription_type) {
            if (subscription_type === 'custom') {
                return 'custom_subscription_' + element_id
            } else {
                return 'subscription_' + element_id
            }
        },
        product_value: function (element_id) {
            return 'product_' + element_id
        },
        target_ids: function () {
            var selected_array = this.selected.split("_")
            var selected_id = parseInt(selected_array[selected_array.length - 1])
            return selected_id
        },
        getCustomer: function ($state) {
            var customerUrl = baseUrl +
                'customers?page=' + this.page_customer +
                '&items_per_page=' + this.$items_per_page +
                '&customer_status=' + this.customer_active +
                '&search=' + this.searchCustomer

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            this.state = $state
            axios.get(customerUrl, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (this.page_customer <= response.data.total_pages) {
                        this.page_customer++
                        this.customers.push(...response.data.items)
                        $state.loaded()
                    } else {
                        $state.complete()
                    }

                })
                .catch(function (error) {
                    // console.log(error)
                })

        },
        getGroup: function ($state) {
            var groupUrl = baseUrl +
                'groups?page=' + this.page_group +
                '&items_per_page=' + this.$items_per_page +
                '&group_status=' + this.group_active +
                '&search=' + this.searchGroup

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            this.state = $state
            axios.get(groupUrl, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (this.page_group <= response.data.total_pages) {
                        this.page_group++
                        this.groups.push(...response.data.items)
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        //very ugly hotfix for items_per_page, because on desktop the request to the second page is not made
        getSubscription: function ($state) {
            var subscriptionUrl = baseUrl +
                'sales/subscriptions?page=' + this.page_subscription +
                '&items_per_page=' + this.$items_per_page +
                '&search=' + this.searchSubscription
            var subscriptionUrlCustom = baseUrl +
                'sales/custom-subscriptions?page=' + this.page_subscription +
                '&items_per_page=100' +
                '&search=' + this.searchSubscription
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            axios.get(subscriptionUrl, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        this.subscriptions.push(...response.data.items)
                    }
                    this.total_subscription_pages = response.data.total_pages
                    if (this.page_subscription < response.data.total_pages) {
                        this.page_subscription++
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                }).then(() => {
                    // Get the Custom Subscriptions, if it's on the last page, and push to the existing list.
                    if (this.page_subscription >= this.total_subscription_pages) {
                        axios.get(subscriptionUrlCustom, {
                                headers: headers
                            })
                            .then(response => {
                                $state = this.state
                                if (response.data.items && response.data.items.length > 0) {
                                    this.subscriptions.push(...response.data.items)
                                }
                                if (this.page_subscription >= response.data.total_pages) {
                                    this.page_subscription++
                                    $state.loaded()
                                } else {
                                    $state.complete()
                                }
                            })
                            .catch(function (error) {
                                // console.log(error)
                            })
                    }
                })

        },
        getCustomSubscriptionServices: function ($state) {
            this.sales.custom_subscription_id = this.sales.custom_subscription_id
            var subscriptionServicesUrlCustom = baseUrl +
                'custom-subscriptions-services/' + this.sales.custom_subscription_id + '?page=' + this.page_custom_subscription_services +
                '&items_per_page=' + this.$items_per_page

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            axios.get(subscriptionServicesUrlCustom, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    this.services_container_height = false
                    if (response.data.items && response.data.items.length > 0) {
                        this.custom_subscription_services = response.data.items
                        this.services_container_height = true
                    }

                    this.getCustomSubscriptionInformation()

                    if (response.data.next_page) {
                        this.page_custom_subscription_services++
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })

        },
        getProduct: function ($state) {
            var productnUrl = baseUrl +
                'sales/products?page=' + this.page_product +
                '&items_per_page=' + this.$items_per_page +
                '&search=' + this.searchProduct +
                '&product_status=' + this.product_active

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }

            this.state = $state
            axios.get(productnUrl, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (this.page_product <= response.data.total_pages) {
                        this.page_product++
                        this.products.push(...response.data.items)
                        $state.loaded()
                    } else {
                        $state.complete()
                    }

                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        getService: function ($state) {
            var serviceUrl = baseUrl +
                'sales/services?page=' + this.page_service +
                '&items_per_page=' + this.$items_per_page +
                '&search=' + this.searchService +
                '&service_status=' + this.service_active

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }

            this.state = $state
            axios.get(serviceUrl, {
                    headers: headers
                })
                .then(response => {
                    if (response.data.items) {
                        this.services.push(...response.data.items)
                    }
                    $state = this.state
                    if ($state) {
                        if (this.page_service <= response.data.total_pages) {
                            this.page_service++
                            $state.loaded()
                        } else {
                            $state.complete()
                        }
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        getCurrentDate: function () {
            var currentDateWithFormat = new Date().toJSON().slice(0, 10)
            return currentDateWithFormat
        },
        registerSale: function () {
            if (this.selected_type == 'group') {
                this.sales.group_id = this.selected_userType
                this.sales.customer_id = 0
            } else if (this.selected_type == 'customer') {
                this.sales.customer_id = this.selected_userType
                this.sales.group_id = 0
            }

            // Reset .sales id values.
            this.resetSalesIds()

            if (this.selected_offers === 'service') {
                // Set the service_id value.
                this.sales.service_id = this.target_ids()
            } else if (this.selected_offers === 'subscription' && this.selected_offers_type === 'custom_subscription') {
                // Set the custom_subscription_id value.
                this.sales.custom_subscription_id = this.target_ids()
            } else if (this.selected_offers === 'subscription' && this.selected_offers_type !== 'custom_subscription') {
                // Set the subscription_id value.
                this.sales.subscription_id = this.target_ids()
            } else if (this.selected_offers == 'product') {
                // Set the product_id value.
                this.sales.product_id = this.target_ids()
            }

            var data = {
                'customer_id': this.sales.customer_id,
                'group_id': this.sales.group_id,
                'service_id': this.sales.service_id,
                'credits': this.sales.credits,
                'product_id': this.sales.product_id,
                'quantity': this.sales.quantity,
                'valid_from': this.sales.valid_from,
                'subscription_id': this.sales.subscription_id,
                'custom_subscription_id': this.sales.custom_subscription_id
            }

            data['selected_services'] = this.sales.selected_services

            // Send the promo_code only if there is value.
            if (this.sales.promo_code) {
                data['promo_code'] = this.sales.promo_code
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            this.sale_compute = data
            axios.post(baseUrl + 'sales/compute', data, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'add_sale_' + response.data.msg + key
                    var translated_message = this.translate(message)

                    if (response.data.status) {
                        this.response_sales_compute = response.data
                        this.showFinish = true
                        this.message = 'Finish the sale'
                        var compute_message = this.translate('add_sale_options_calculated' + key)
                        this.$toasted.success(compute_message, {
                            duration: this.$toasted_duration
                        })

                        // Go to bottom of the panel.
                        this.go_to_bottom = true
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    if (error) {
                        this.$toasted.error('An error has occured', {
                            duration: this.$toasted_duration
                        })
                    }
                })
        },
        createSale: function () {
            var data = {
                'customer_id': this.sale_compute.customer_id,
                'group_id': this.sale_compute.group_id,
                'service_id': this.sale_compute.service_id,
                'credits': this.sale_compute.credits,
                'product_id': this.sale_compute.product_id,
                'quantity': this.sale_compute.quantity,
                'valid_from': this.sale_compute.valid_from,
                'subscription_id': this.sale_compute.subscription_id,
                'custom_subscription_id': this.sale_compute.custom_subscription_id,

                'amount': this.response_sales_compute.amount,
                'promo_code_id': this.response_sales_compute.promo_code_id,
                'promo_standard_id': this.response_sales_compute.promo_standard_id,
                'status': this.response_sales_compute.status,
                'valid_until': this.response_sales_compute.valid_until,
            }

            data['selected_services'] = this.sales.selected_services

            // Send the promo_code only if there is value.
            if (this.sales.promo_code) {
                data['promo_code'] = this.sales.promo_code
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            axios.post(baseUrl + 'sales', data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'add_sale_' + response.data.msg + key
                    var translated_message = this.translate(message)

                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: response.data.status
                        })
                    } else {
                        this.$toasted.error('The action couldn\'t be made', {
                            duration: this.$toasted_duration
                        })
                    }

                })
                .catch(error => {
                    var msg = error.response.data.msg
                    if (msg != undefined) {
                        this.msg = msg
                        this.$toasted.error('An error has occured', {
                            duration: this.$toasted_duration
                        })
                    }
                })
        },
        closePanel: function () {
            this.$emit('closePanel')
        }
    }
}
</script>

<style lang="scss" scoped>
.card_scroll {
    position: relative;
    max-height: calc(50px * 4);
    overflow-y: auto;

    &.card_scroll_doubled {
        max-height: calc(50px * 8);
    }
}

.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    padding-top: 0;
    padding-bottom: 0;
    min-height: 35px;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    &[disabled="disabled"] {
        border-color: rgba(0, 0, 0, .2);
        background: rgba(0, 0, 0, .2);
    }

    .q-radio {
        transform: scale(0.85);
    }
}

.form-content {
    max-height: 84vh;
    overflow-y: auto;
    overflow-x: hidden;
}

body .list_wrapper .q-item.row {
    width: 100%;
}

.included-services-block {
    padding: 0.5rem !important;
    background-color: #defffa;
    border-top: 2px solid #59a9c7;
    border-bottom: 2px solid #59a9c7;
}

.custom-control-label .q-badge {
    border-radius: 0;
}

@media screen and (max-width: 767px) {
    .custom-control-label .q-badge {
        display: flex;
        width: calc(100vw - 35px) !important;
        margin: 1px -25px !important;

        &:first-child {
            margin-top: 5px !important;
        }
    }
}
</style>
